import { render, staticRenderFns } from "./MyProfile.vue?vue&type=template&id=311974c8&scoped=true"
import script from "./MyProfile.vue?vue&type=script&lang=ts"
export * from "./MyProfile.vue?vue&type=script&lang=ts"
import style0 from "./MyProfile.vue?vue&type=style&index=0&id=311974c8&prod&lang=scss&scoped=true"
import style1 from "./MyProfile.vue?vue&type=style&index=1&id=311974c8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "311974c8",
  null
  
)

export default component.exports