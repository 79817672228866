






















import { extend } from 'vee-validate';
import {
  required,
  min,
  confirmed,
} from 'vee-validate/dist/rules';
import { SfTabs } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { customerPasswordRegExp, invalidPasswordMsg } from '~/modules/customer/helpers/passwordValidation';
import ProfileUpdateForm from '~/modules/customer/pages/MyAccount/MyProfile/ProfileUpdateForm.vue';
import PasswordResetForm from '~/modules/customer/pages/MyAccount/MyProfile/PasswordResetForm.vue';
import AccountDetailsForm from '~/modules/customer/pages/MyAccount/MyProfile/AccountDetails.vue';
import type { OnFormComplete, OnFormError, SubmitEventPayload } from '~/modules/customer/types/form';
import type { ProfileUpdateFormFields, PasswordResetFormFields } from '~/modules/customer/pages/MyAccount/MyProfile/types';
import BreadCrumb from "~/almarwan/components/Breadcrumb.vue";
import Icon from "~/almarwan/components/Icon.vue";
import {eventBus} from "@/almarwan/helpers/EventBus";
import UpcomingPayments from '~/modules/customer/pages/MyAccount/UpcomingPayments.vue'
import AccountPayments from "~/modules/customer/pages/MyAccount/AccountPayments.vue";


extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value: string) => customerPasswordRegExp.test(value),
});

extend('confirmed', {
  ...confirmed,
  message: 'Passwords don\'t match',
});

export default defineComponent({
  name: 'MyProfile',
  data() {
    return {
      applySlugColor: true
    }
  },
  components: {
    AccountPayments,
    BreadCrumb,
    Icon,
    SfTabs,
    ProfileUpdateForm,
    PasswordResetForm,
    AccountDetailsForm,
    UpcomingPayments
  },
  methods: {
    toggleSidebar() {
      let sidebar = document.querySelector('.account__sidebar');

      if (sidebar.classList.contains('active')) {
        sidebar.classList.remove('active')
      } else {
        sidebar.classList.add('active')
      }
    }
  },
  mounted () {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      if(localStorage.getItem("mobileNavLoadedOnce") === null){
        localStorage.setItem('mobileNavLoadedOnce', 'true');
        let sidebar = document.querySelector('.account__sidebar');
        sidebar.classList.add('active');
        let bodyElement = document.querySelector('body')
        bodyElement.style.overflow = 'hidden';
      }
    }
    eventBus.$on('toggleProfileMenu', (val) => {
      if(val) {
        let sidebar = document.querySelector('.account__sidebar');
        sidebar.classList.remove('active')
        let bodyElement = document.querySelector('body')
        bodyElement.style.overflow = '';
      }
    })
  },
  setup() {
    const {
      changePassword,
      loading,
      updateUser,
      updateUserEmail,
      error,
    } = useUser();

    const formHandler = async <T extends () => Promise<unknown>>(
      onSubmit: T,
      onComplete: OnFormComplete,
      onError: OnFormError,
    ) => {
      await onSubmit();
      const actionErr = error.value.changePassword || error.value.updateUser;
      if (actionErr) {
        onError(actionErr.toString());
      } else {
        onComplete();
      }
    };

    const updateEmailData = ({
      form,
      onComplete,
      onError,
    } : SubmitEventPayload<ProfileUpdateFormFields>) => formHandler(
      async () => updateUserEmail({ user: form }),
      onComplete,
      onError,
    );

    const updatePersonalData = ({
      form,
      onComplete,
      onError,
    } : SubmitEventPayload<ProfileUpdateFormFields>) => formHandler(
      async () => updateUser({ user: form }),
      onComplete,
      onError,
    );

    const updatePassword = ({
      form,
      onComplete,
      onError,
    } : SubmitEventPayload<PasswordResetFormFields>) => formHandler(
      async () => changePassword({ current: form.currentPassword, new: form.newPassword }),
      onComplete,
      onError,
    );

    const breadcrumbs = [{
      link: '/customer/my-profile',
      text: 'Account'
    }]

    return {
      breadcrumbs,
      loading,
      updateEmailData,
      updatePersonalData,
      updatePassword,
    };
  },
});
