





































































































































































































































import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
import { SfInput, SfModal } from '@storefront-ui/vue';
import { defineComponent, ref, computed, onMounted, watch } from '@nuxtjs/composition-api';
import userGetters from '~/modules/customer/getters/userGetters';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUiNotification, useUiState } from '~/composables';
import { useNewsletter } from '~/composables/useNewsletter';

import type { OnFormComplete, OnFormError, SubmitEventPayload } from '~/modules/customer/types/form';
import type { ProfileUpdateFormFields } from '~/modules/customer/pages/MyAccount/MyProfile/types';
import Icon from "~/almarwan/components/Icon.vue";
import { eventBus } from "@/almarwan/helpers/EventBus"

extend('email', {
  ...email,
  message: 'The email field must be a valid email',
});

export default defineComponent({
  components: {
    SfInput,
    SfModal,
    ValidationProvider,
    ValidationObserver,
    Icon
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['submit'],
  setup(_props, { emit }) {
    const activeRow = ref('');
    const { updateSubscription, undoSubscription } = useNewsletter();
    const { user } = useUser();
    const { setUserNotified, userNotified } = useUiState();
    const currentPassword = ref('');
    const requirePassword = ref(false);
    const submitting = ref(false);
    const getInitialForm = () : ProfileUpdateFormFields => ({
      firstname: userGetters.getFirstName(user.value),
      // lastname: userGetters.getLastName(user.value),
      email: userGetters.getEmailAddress(user.value),
      telephone: user.value['telephone'] !== null? user.value['telephone'] : 'NA',
      company_local: user.value['company_local'] !== null? user.value['company_local'] : 'NA',
      role: user.value['role'] !== null? user.value['role'] : 'NA',
    });
    const createdAt = computed (() => {
      if (user.value) {
        var joiningDt = user.value && user?.value['created_at']?.split(' ');
        if (joiningDt) {
          joiningDt[0] = formatDate(joiningDt[0]);
          return joiningDt[0];
        }
      }
    });
    const lastLogin =  ref();
    const isSubscribed = ref(user.value['is_subscribed']? 'Yes' : 'No');
    const formatDate = (input) => {
      var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1], day = datePart[2];
      return day+'.'+month+'.'+year;
    }
    //temp date until backend update..
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    lastLogin.value = dd + '.' + mm + '.' + yyyy;

    const subscribe = async () => {
      activeRow.value = ''
      const response = await updateSubscription({ email: userGetters.getEmailAddress(user.value) });
      if(response.hasOwnProperty('errors')){
        sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist'),
          // @ts-ignore: Unreachable code error
          message: response?.errors[0].message,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Newsletter',
        });
      }
      else {
        isSubscribed.value = 'Yes';
        sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist'),
          // @ts-ignore: Unreachable code error
          message: 'Successfully subscribed to our newsletter',
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Newsletter',
        });
      }
    };

    const unsubscribe = async () => {
      activeRow.value = ''
      const response = await undoSubscription({ email: userGetters.getEmailAddress(user.value) });
      if(response.hasOwnProperty('errors')){
        sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist'),
          // @ts-ignore: Unreachable code error
          message: response?.errors[0].message,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Newsletter',
        });
      }
      else {
        isSubscribed.value = 'No';
        sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist'),
          // @ts-ignore: Unreachable code error
          message: 'Successfully unsubscribed from our newsletter',
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Newsletter',
        });
      }
    };

    const { send: sendNotification } = useUiNotification();

    const form = ref(getInitialForm());
    const successCall = () => {
      activeRow.value = ''
      sendNotification({
        id: Symbol('user_updated'),
        message: 'The user account data updated successfully!',
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'User Account',
      });
    }
    const submitForm = (resetValidationFn: () => void) => () => {
      const onComplete = () => {
        submitting.value = true
        form.value = getInitialForm();
        requirePassword.value = false;
        currentPassword.value = '';
        sendNotification({
          id: Symbol('user_updated'),
          message: 'The user account data updated successfully!',
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'User Account',
        });
        resetValidationFn();
      };

      const onError = (message: string) => {
        submitting.value = false
        sendNotification({
          id: Symbol('user_updated'),
          message,
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'User Account',
        });
      };

      const isEmailChanged = userGetters.getEmailAddress(user.value) !== form.value.email;

      if (isEmailChanged && !requirePassword.value) {
        requirePassword.value = true;
      } else {
        if (currentPassword.value) {
          form.value.password = currentPassword.value;
        }

        const eventPayload : SubmitEventPayload<ProfileUpdateFormFields> = { form: form.value, onComplete, onError };

        emit('submit', eventPayload);
      }
    };
    onMounted(() => {
      if(!userNotified.value && (user.value['telephone'] == null || user.value['telephone'] === 'NA' )) {
        activeRow.value = 'phone'
        sendNotification({
          id: Symbol('user_updated'),
          message: 'Please add your phone number to complete your registration.',
          type: 'warning',
          icon: 'error',
          persist: false,
          title: 'User Account',
        });
        if (window?.scroll) {
          window.scrollTo(0, window.scrollY + 450)
        }
        setUserNotified(true)
      }
    })
    const clearInput= (item) =>{
      form.value[item]='';

    }
    const cancelClicked = () => {
      activeRow.value = ''
      eventBus.$emit('cancelClicked')
      form.value = getInitialForm();
    }

    return {
      successCall,
      submitting,
      requirePassword,
      currentPassword,
      form,
      submitForm,
      activeRow,
      createdAt,
      isSubscribed,
      subscribe,
      lastLogin,
      unsubscribe,
      clearInput,
      cancelClicked
    };
  },
});
