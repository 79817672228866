




















































































































import { useUser } from '~/modules/customer/composables/useUser';
import { useAddToCart } from '~/helpers/cart/addToCart';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import {defineComponent, useFetch, useRouter, ref} from "@nuxtjs/composition-api";
import useUiHelpers from "../../../../composables/useUiHelpers";

export default defineComponent({
  components: {
    SkeletonLoader
  },
  setup() {
    const { addItemToCart } = useAddToCart();
    const { numberWithCommas } = useUiHelpers();
    const router = useRouter();
    const { isAuthenticated, makeOfferWithOrder, loading: loadingPayOrder } = useUser();
    const offerList = ref(null)
    const buttonClicked = ref(false)
    const makeOfferButtonClicked = ref(false)
    const paymentSuccess = ref('')
    const remainingPayments = ref({})
    const addProductToCart = (prod) => {
      makeOfferButtonClicked.value = true
      addItemToCart({ product: prod.product, quantity: 1, custom_option: { finalize_makeoffer: prod.id } }).then(()=>{
        return makeOfferButtonClicked.value = false
      });
    }

    const payRemainingBalance = async (item, from) =>  {
      buttonClicked.value = true
      router.push({ path: '/checkout', query: { from: 'orders', id: item.increment_id} });
    }
    const installCount = (installment) => {
      let count  = installment.filter((install) => install.status !== 'Paid')
      return count.length - 1
    }

    const getDate = (date) => {
      const currentDate = new Date(date);
      return currentDate.toLocaleDateString();
    }

    useFetch(async ()=> {
      if (isAuthenticated.value) {
        const result = await makeOfferWithOrder()
        const { makeOffers } = result;
        const { customer } = result;
        const ordersWithInstallments = customer?.orders?.items
          .filter((item) => item.installments.length > 0)
          .map((item) => ({
            ...item.finance_request,
            installments: item.installments.map((installment) => ({
              ...installment,
              parentItemId: Number(atob(item.id)),
            })),
          }))
          .filter((order) => {
            return order.installments.map((installment) => (
              installment.state !== 'Paid' && isDateInFutureAndCloserToCurrent(installment.due_date)
            ));
          });

        const addNumberOfUnpaidInstallment = ordersWithInstallments?.map((order) => {
          return {
            ...order,
            unpaidInstallment: order.installments.filter((installment) => installment.state === null).length
          }
        })

        const getUnpaidAndUpcomingInstallment = addNumberOfUnpaidInstallment?.flatMap((prod) => {
          return prod.installments.map((installment, index, arr) => {
            if (installment.state !== 'Paid') {
              const dueDate = new Date(installment.due_date);
              const currentDate = new Date();
              const thirtyDaysFromNow = new Date();
              thirtyDaysFromNow.setDate(currentDate.getDate() + 30);
              return {
                ...installment,
                next_due_date: installment.due_date
              };
            }
            return null;
          }).filter(Boolean);
        });
        const ordersWithoutInstallments = customer.orders.items?.filter((item) => item.installments.length === 0 && item.status === 'Processing')
          .map((item) => ({
            ...item,
          }));

        const checkMakeOfferStatus = makeOffers?.items?.filter((offer) => offer.status === 'Conditionally Accepted' && offer.order !== null)

        remainingPayments.value = {
          makeOffers: checkMakeOfferStatus,
          orders: [...ordersWithoutInstallments],
          installments: getUnpaidAndUpcomingInstallment
        };
      }
    })

    const isDateInFutureAndCloserToCurrent = (targetDate) => {
      const currentDate = new Date();
      const targetDateTime = new Date(targetDate);
      if (targetDateTime > currentDate) {
        const timeDifference = targetDateTime.getTime() - currentDate.getTime();
        const thresholdInMilliseconds = 30 * 24 * 60 * 60 * 1000;
         if (timeDifference < thresholdInMilliseconds) {
          return true;
        }
      }
      return false;
    }

    const convertToInstallment = (id) => {
      let decodeId = atob(id)
      if (decodeId) {
        router.push(`/credit-application?order_id=${decodeId}`)
      } else {
        router.push(`/credit-application`)
      }
    }

    return {
      installCount,
      convertToInstallment,
      getDate,
      numberWithCommas,
      makeOfferButtonClicked,
      buttonClicked,
      paymentSuccess,
      loadingPayOrder,
      payRemainingBalance,
      remainingPayments,
      offerList,
      addProductToCart
    }
  },
})
