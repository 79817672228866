var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account__form"},[_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__group",class:[{active: _vm.activeRow === 'password'}]},[_c('div',{staticClass:"form__group-info"},[_vm._m(0),_vm._v(" "),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.setActiveTag.apply(null, arguments)}}},[_vm._v("Change")])]),_vm._v(" "),_c('div',{staticClass:"form__group-edit",style:(_vm.activeRow == 'password' ? 'display: block' : '')},[_c('span',{staticClass:"pwd-edit-title"},[_vm._v("Password")]),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.submitForm(reset))}}},[_c('div',{staticClass:"form__group-edit"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form__input-placeholder",class:[{active: _vm.activeRow === 'password'},{ 'has-error': _vm.activeRow === 'password' && errors[0] }]},[_c('span',{staticClass:"form__input-label",class:[{'error-label' : _vm.activeRow === 'password' && errors[0] }]},[_vm._v("Current Password")]),_vm._v(" "),(_vm.activeRow === 'password' && _vm.form.currentPassword && !errors[0])?_c('nuxt-picture',{staticClass:"clear-icon-profile",attrs:{"src":"/images/global/x.svg","alt":"clear"},on:{"click":function($event){return _vm.clearInput('currentPassword')}}}):_vm._e(),_vm._v(" "),(_vm.activeRow != 'password' && _vm.form.currentPassword && !errors[0])?_c('nuxt-picture',{staticClass:"clear-icon-profile",attrs:{"src":"/images/global/check.svg","alt":"valid"}}):_vm._e(),_vm._v(" "),(errors[0])?_c('nuxt-picture',{staticClass:"clear-icon-profile",attrs:{"src":"/images/global/error-red.svg","alt":"error"}}):_vm._e(),_vm._v(" "),_c('SfInput',{attrs:{"type":"password","name":"currentPassword","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.currentPassword),callback:function ($$v) {_vm.$set(_vm.form, "currentPassword", $$v)},expression:"form.currentPassword"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('div',{staticClass:"form__group-edit"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|password","mode":"lazy","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form__input-placeholder",class:[{active: _vm.activeRow === 'password'},{ 'has-error': _vm.activeRow === 'password' && errors[0] }]},[_c('span',{staticClass:"form__input-label",class:[{'error-label' : ( _vm.activeRow === 'password' && errors[0]) }]},[_vm._v("New Password")]),_vm._v(" "),( _vm.activeRow === 'password' && _vm.form.newPassword && !errors[0])?_c('nuxt-picture',{staticClass:"clear-icon-profile",attrs:{"src":"/images/global/x.svg","alt":"clear"},on:{"click":function($event){return _vm.clearInput('newPassword')}}}):_vm._e(),_vm._v(" "),(_vm.activeRow != 'password' && _vm.form.newPassword && !errors[0])?_c('nuxt-picture',{staticClass:"clear-icon-profile",attrs:{"src":"/images/global/check.svg","alt":"valid"}}):_vm._e(),_vm._v(" "),(errors[0])?_c('nuxt-picture',{staticClass:"clear-icon-profile",attrs:{"src":"/images/global/error-red.svg","alt":"error"}}):_vm._e(),_vm._v(" "),_c('SfInput',{attrs:{"type":"password","name":"newPassword","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__group-edit repeat-pwd"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"mode":"lazy","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form__input-placeholder",class:[{active: _vm.activeRow === 'password'},{ 'has-error': (_vm.activeRow === 'password' && errors[0] )}]},[_c('span',{staticClass:"form__input-label",class:[{'error-label' :(_vm.activeRow === 'password' && errors[0] )}]},[_vm._v("Repeat New Password")]),_vm._v(" "),( _vm.activeRow === 'password' && _vm.form.repeatPassword && !errors[0])?_c('nuxt-picture',{staticClass:"clear-icon-profile",attrs:{"src":"/images/global/x.svg","alt":"clear"},on:{"click":function($event){return _vm.clearInput('repeatPassword')}}}):_vm._e(),_vm._v(" "),(_vm.activeRow != 'password' && _vm.form.repeatPassword && !errors[0])?_c('nuxt-picture',{staticClass:"clear-icon-profile",attrs:{"src":"/images/global/check.svg","alt":"valid"}}):_vm._e(),_vm._v(" "),(errors[0])?_c('nuxt-picture',{staticClass:"clear-icon-profile",attrs:{"src":"/images/global/error-red.svg","alt":"error"}}):_vm._e(),_vm._v(" "),_c('SfInput',{attrs:{"type":"password","name":"repeatPassword","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.repeatPassword),callback:function ($$v) {_vm.$set(_vm.form, "repeatPassword", $$v)},expression:"form.repeatPassword"}})],1)]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form__footer"},[_c('div',{staticClass:"form__group-edit"},[_c('button',{staticClass:"btn--orange pwd-submit",attrs:{"type":"submit"}},[_vm._v("\n                  Apply\n                ")])])])])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_vm._v("Password")]),_vm._v(" "),_c('p',[_vm._v("********")])])}]

export { render, staticRenderFns }