































































































































































import { defineComponent, ref, onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { SfInput } from '@storefront-ui/vue';
import { useUiNotification } from '~/composables';
import type { SubmitEventPayload } from '~/modules/customer/types/form';
import type { PasswordResetFormFields } from '~/modules/customer/pages/MyAccount/MyProfile/types';
import {eventBus} from '@/almarwan/helpers/EventBus';

export default defineComponent({
  components: {
    SfInput,
    ValidationProvider,
    ValidationObserver,
  },
  beforeDestroy() {
    eventBus.$off('currentSection');
  },
  setup(_, { emit }) {
    const getInitialForm = () : PasswordResetFormFields => ({
      currentPassword: '',
      newPassword: '',
      repeatPassword: '',
    });
    const {
      send: sendNotification,
    } = useUiNotification();
    const activeRow = ref('');
    const form = ref(getInitialForm());

    const submitForm = (resetValidationFn: () => void) => () => {
      const onComplete = () => {
        form.value = getInitialForm();
        activeRow.value = '';
        emit('currentSection', '');
        sendNotification({
          id: Symbol('password_updated'),
          message: 'The user password was changed successfully updated!',
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'User Account',
        });
        resetValidationFn();
      };

      const onError = (message: string) => {
        sendNotification({
          id: Symbol('password_not_updated'),
          message,
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'User Account',
        });
      };

      const eventPayload : SubmitEventPayload<PasswordResetFormFields> = { form: form.value, onComplete, onError };

      emit('submit', eventPayload);
    };
    const setActiveTag = () => {
      activeRow.value = 'password'
      emit('currentSection', 'password');
    }
    const clearInput= (item) =>{
      form.value[item]='';
    }
    onMounted(async () => {
      eventBus.$on('currentSection', (val) => {
        if(val !== 'password'){
          activeRow.value = ''
        }
        else {
          activeRow.value = 'password'
        }

      });
      eventBus.$on('cancelClicked', () => {
        activeRow.value = ''
      })
    });
    onBeforeUnmount(() => {
      eventBus.$off('cancelClicked')
    })

    return {
      form,
      submitForm,
      activeRow,
      setActiveTag,
      clearInput
    };
  },
});
