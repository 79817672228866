




















import {defineComponent} from "@nuxtjs/composition-api";
import UpcomingPayments from "~/modules/customer/pages/MyAccount/UpcomingPayments.vue";
import BreadCrumb from "~/almarwan/components/Breadcrumb.vue";
import Icon from "~/almarwan/components/Icon.vue";

export default defineComponent({
  name: 'AccountPayments',
  components: {
    BreadCrumb,
    UpcomingPayments,
    Icon
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  }
})
